/* =========== Google Fonts ============ */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

/* =============== Globals ============== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --blue: #fff;
  --white: #000;
  --gray: #f5f5f5;
  --black1: #fff;
  --black2: #999;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  position: relative;
  width: 100%;
}
.navigation {
  left: 15px;
  border-radius: 20px;
  top: 18px;
  /* position: fixed; */
  width: 300px;
  height: 100%;
  background: #03544c;
  transition: 0.5s;
  overflow-y: auto;
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
}
.navigation.active {
  width: 80px;
}

.wrapper {
  width: 100%;
}

.navigation ul {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
}
/* .logout button {
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  cursor: pointer;
  border-radius: 18px;
  border: none;
  font-family: "Ubuntu", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #03544c;
}
.logout > button > i {
  font-size: 1.75rem;
  color: #03544c;
  margin-right: 10px;
} */
.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-radius: 15px;
  margin-bottom: 10px;
}

.navigation ul li:hover,
.navigation ul li.hovered {
  background-color: #fff;
  transition: 0.7s;
}

.navigation ul li:hover a {
  color: #03544c;
}

/* .logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
} */

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #fff;
}
.active a,
.active a {
  transition: 0.7s;
  color: #000;
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 75px;
  text-align: center;
}
.navigation ul li a .icon i {
  font-size: 1.75rem;
}

.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}

/* .logout {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.links {
  background-color: transparent;
  transition: 0.7s;
}

.active{
  color: red !important;
}

/* .active {
  background-color: #fff;
  color: #03544c !important;
  transition: 0.7s;
  border-radius: 15px;
} */

/* --------- curve outside ---------- */
/* .navigation ul li:hover a::before,
.navigation ul li.hovered a::before {
  transition: 0.7s;
  content: "";
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px var(--white);
  pointer-events: none;
}
.navigation ul li:hover a::after,
.navigation ul li.hovered a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px var(--white);
  pointer-events: none;
} */
